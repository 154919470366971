<template>

      <v-container fluid style="height: 100%;">

        <v-row align="center" justify="center" style="height: 100%;">
          <v-col cols="12" sm="6" md="4">
            <v-card class="elevation-12">
              <v-toolbar dark color="info">
                <v-toolbar-title class="white--text">Formulario de ingreso</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
      
              <v-form @submit.prevent="login" id="login-form">
              <v-card-text>
                  <v-text-field id="email" prepend-icon="mdi-account" name="email" label="Correo electrónico" type="text" v-model="user.email"></v-text-field>
                  <v-text-field id="password" prepend-icon="mdi-lock" name="password" label="Contraseña" type="password" v-model="user.password"></v-text-field>
              </v-card-text>
              
              <v-card-actions>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-btn dark block large color="black" form="login-form" type="submit">Ingresar</v-btn>
                    </v-col>
                </v-row>
              </v-card-actions>

              </v-form>

            </v-card>

              <div v-if="error">
                  <v-alert
                    v-model="alert"
                    dismissible
                    type="error"
                  >
                    {{ error.message }}
                  </v-alert>
              </div>

              <div v-if="success">
                  <v-alert
                    v-model="alertSuccess"
                    dismissible
                    type="success"
                  >
                    {{ success.message }}
                  </v-alert>
              </div>

          </v-col>
        </v-row>


      </v-container>

</template>

<script>
/* eslint-disable */
import Parse from 'parse'

  export default {
    mounted (){
      //console.log(Parse.User.current())
      if(Parse.User.current()){
         this.$router.push('/admin')
      }
    },
    data: () => ({
      drawer: null,
      user: {
        email: null,
        password: null
      },
      error: null,
      alert: true,
      success: null,
      alertSuccess: true,
      emailReset: '',
      resetModal: false
    }),
    props: {
      source: String
    },
    methods: {
      async login () {
        let self = this
        self.error = null

        try {

          if (!self.user.email || !self.user.password) {
            self.error = { message: "Los campos no deben estar vacios" };
            throw self.error;
          }

          let email_login = self.user.email.trim();
          email_login = email_login.toLowerCase();

          await Parse.User.logIn(email_login, self.user.password);
          //self.$emit('loginSuccess', res)
          const loggedIn = Parse.User.current();

          const isAdmin = loggedIn.attributes.assistant;

          this.$router.go('/')

        } catch (error) {
          this.alert = true
          this.error = error
          console.log(error.message)
        }
      }
    }
  }
</script>